<template>
  <div>
    <div class="search">
      <div class="searchFl">
        <portSelect ref="portSelectRef" v-model="searchQuery.dest_port" placeTitle="请输入目的港" clearable />
        <!-- 委托单位 -->
        <shipCompany
          :placeTitle="'船司代码或中文名'"
          style="margin-left:20px"
          ref="shipCompanyRef"
          v-model="searchQuery.shipping_company"
        ></shipCompany>
        <el-date-picker :clearable='true' style="margin-left:20px" v-model="searchQuery.etd_time" type="daterange"
          size="small" range-separator="-" start-placeholder="开航开始日期" end-placeholder="开航结束日期">
        </el-date-picker>
        <el-date-picker :clearable='true' style="margin-left:20px" v-model="searchQuery.created_time" type="daterange"
          size="small" range-separator="-" start-placeholder="创建开始日期" end-placeholder="创建结束日期">
        </el-date-picker>
        <el-select v-model="searchQuery.is_self" clearable placeholder="查看范围" style="width:230px;margin-left:20px" size="small" >
          <el-option label="查看全部" :value="0"></el-option>
          <el-option label="仅看自己" :value="1"></el-option>
        </el-select>
      </div>
      <div>
        <el-button size="small" type="primary" @click="searchChange">查询</el-button>
        <el-button size="small" @click="resetChange">{{
            $t("reset")
        }}</el-button>
      </div>
    </div>
    <div class="line"></div>
    <div class="tableBlock" style="padding-top:10px">
      <el-table :data="tableData" style="width: 100%;" :height="tableHeight" sortable="custom" stripe :border="true"
        ref="tbaleRef" v-tableHeight="{ bottomOffset: 74 }" :tableLoading="loading" current-row-key="id">
        <el-table-column label="港口信息" width="320" :show-overflow-tooltip="true" align="center">
          <div slot-scope="scope" class="protMsg">
            <div style="width:30px">
              <template v-if="scope.row.is_read != 1"><img width="30px" src="https://ym-attached-file.oss-cn-shanghai.aliyuncs.com/images/icon_new.png" alt=""></template>
            </div>
            <div class="from">
              <p class="textOverflow" style="color:#91be42">{{ scope.row.start_port_name_en | textFormat }}</p>
              <p class="textOverflow">{{ scope.row.start_port_name_zh | textFormat }}</p>
            </div>
            <div class="jt" style="color:#7D8695">>>></div>
            <div class="dest">
              <p class="textOverflow" style="color:#91be42">{{ scope.row.end_port_name_en | textFormat }}</p>
              <p class="textOverflow">{{ scope.row.end_port_name_zh | textFormat }}</p>
            </div>
          </div>
        </el-table-column>
        <el-table-column label="船公司" :show-overflow-tooltip="true" width="100">
          <template slot-scope="scope">{{ scope.row.shipping_company_code ||
              scope.row.shipping_company_name_zh | textFormat
          }}</template>
        </el-table-column>
        <el-table-column label="开航日期" width="130" :show-overflow-tooltip="true">
          <div slot-scope="scope">{{ scope.row.etd | secondFormat('LL') }}</div>
        </el-table-column>
        <el-table-column label="海运费" :show-overflow-tooltip="true" align="center" width="200">
          <div slot-scope="scope" class="seafee" v-if="scope.row.sea_freight">
            <div v-for="(item,index) in scope.row.sea_freight" :key="index">
              {{item.currency | textFormat}} <span class="red">{{item.price | textFormat}}</span>/ {{item.name | textFormat}}*{{item.quantity || 1}}
            </div>
          </div>
          <span v-else>-</span>
        </el-table-column>
        <el-table-column label="UNNO" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{ scope.row.un_no | textFormat }}</template>
        </el-table-column>
        <el-table-column label="有效期" :show-overflow-tooltip="true" width="135">
          <template slot-scope="scope" >
            <span v-if="scope.row.validity_start && scope.row.validity_end">{{ scope.row.validity_start | secondFormat("L1") }}至{{ scope.row.validity_end | secondFormat("L1") }}</span> 
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column label="订舱客户" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{ scope.row.booking_customer | textFormat }}</template>
        </el-table-column>
        <el-table-column label="销售" :show-overflow-tooltip="true" >
          <template slot-scope="scope">{{ scope.row.sales | textFormat }}</template>
        </el-table-column>
        <el-table-column label="商务" :show-overflow-tooltip="true" >
          <template slot-scope="scope">{{ scope.row.business | textFormat }}</template>
        </el-table-column>
        <el-table-column label="状态" :show-overflow-tooltip="true" width="135">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 0" class="green">待回复</span>
            <span v-if="scope.row.status == 1">有舱位</span>
            <span v-if="scope.row.status == 2">有舱位（新运价）</span>
            <span v-if="scope.row.status == 3" class="red">无舱位</span>
            <span v-if="scope.row.status == 4" class="gray">撤销</span>
          </template>
        </el-table-column>
        <el-table-column width="105">
          <el-button type="warning" size="mini" slot-scope="scope" @click="handleDetail(scope.row)">详情</el-button>
        </el-table-column>
      </el-table>
    </div>
    <div class="paginationBlock">
      <el-pagination @current-change="handleChange" :page-size="10" layout="total, prev, pager, next" :total="total">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import portSelect from "~ymp/components/common/portSelect";
import shipCompany from "~ymp/components/common/shipCompany"
export default {
  data() {
    return {
      loading: false,
      total: 0,
      currentPage: 1,
      tableHeight: 100,
      tableData: [],
      searchQuery: {
        dest_port: "",//目的港
        shipping_company: "",//  合作伙伴公司名== 委托单位
        etd_time: [],// 开航时间
        created_time: [],// 创建时间
        is_self: 0
      },
      dialog: false,
      dialogType: 'add',
      detail: {}
    };
  },
  components: {
    portSelect,
    shipCompany
  },
  mounted() {
    this.getList();
  },
  filters: {
    status_format(e) {
      let obj = {
        0: "待审核",
        1: "正操作",
        2: "预提交",
        3: "已提交",
        4: "已审核",
        5: "已退关",
        6: "已拒接",
      }
      return obj[e]
    },
    kgsFormat(e) {
      let str = '-'
      if (e) {
        str = Number(e).toFixed(3) + 'KGS'
      }
      return str
    }
  },
  methods: {
    searchChange() {
      this.currentPage = 1
      this.getList()
    },
    handleChange(e) {
      this.currentPage = e;
      this.getList();
    },
    resetChange(e) {
      this.searchQuery = {
        dest_port: "",//目的港
        shipping_company: "",//  合作伙伴公司名== 委托单位
        etd_time: [],// 开航时间
        created_time: [],// 创建时间
        is_self: 0
      }
      this.$refs.shipCompanyRef.selectClear()
      this.$refs.portSelectRef.selectClear()
    },
    async getList() {
      this.loading = true;
      let obj = Object.assign({}, this.searchQuery)
      if (obj.etd_time && obj.etd_time.length) {
        obj.etd_start = this.$moment(obj.etd_time[0]).startOf('day').unix()
        obj.etd_end = this.$moment(obj.etd_time[1]).endOf('day').unix()
      }
      if (obj.created_time && obj.created_time.length) {
        obj.created_time_start = this.$moment(obj.created_time[0]).startOf('day').unix()
        obj.created_time_end = this.$moment(obj.created_time[1]).endOf('day').unix()
      }
      obj.sales_id = this.USER_INFO.id
      delete obj.etd_time
      delete obj.created_time
      let params = {
        limit: 10,
        start: this.currentPage - 1,
      };
      params = Object.assign(params, obj)
      let result = await this.$store.dispatch(
        "baseConsole/get_shipping_consultings",
        params
      );
      this.loading = false;
      this.tableData = []
      if (result && result.success) {
        this.tableData = [].concat(result.data);
        this.total = result.count;
      }
    },
    // 查看详情 
    handleDetail(e) {
      let path =  e.status == 0 || e.status == 4 ?  '/console/consulting/detail':'/console/consulting/manydetail';
      this.$router.push({
        path: path,
        query: {
          id: e.id,
        }
      })
    }
  }
}
</script>
<style scoped lang="less">
.search {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 10px;
  justify-content: space-between;

  .searchFl {
    width: 90%;
    display: flex;
    align-items: center;

    .searchInput {
      width: 15%;
      padding: 0 6px;
    }
  }
}

.green {
  color: #36b37e;
}

.red {
  color: red;
}

.gray {
  color: #c0c4cc;
}
.seafee{
  display: flex;
  flex-direction: column;
}

.protMsg {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .from {
    width: 100px;

    p {
      width: 100%;
    }
  }

  .jt {
    width: 50px;
    color: #027fff;
    line-height: 50px;
  }

  .dest {
    width: 100px;

    p {
      width: 100%;
    }
  }
}
</style>